// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

let windowWidth = window.innerWidth;
let params = {};

if (windowWidth <= 768) {
    params = {

        loop: true,

        // If we need pagination
        pagination: {
            el: ".swiper-pagination",
        },
        // Navigation arrows
        // navigation: {
        //     nextEl: '.next-slide',
        //     prevEl: '.prev-slide',
        // },
    }
} else if (windowWidth >= 769) {
    params = {
        effect: 'fade',
        loop: true,

        // If we need pagination
        dots: false,
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        // Navigation arrows
        navigation: {
            nextEl: '.next-slide',
            prevEl: '.prev-slide',
        },
    }
}

let swiper = new Swiper(".about-slider", params);




const caseSwiper = new Swiper(".case-history-slider", {
    slidesPerView: 1.3,
    spaceBetween: 0,
    centeredSlides: true,
    loop: false,

});
const knowMoreBtns = document.querySelectorAll('.know-more-icon');
knowMoreBtns.forEach((el) => {
    el.addEventListener('click', () => {
        const casePopup = document.querySelector(`.slide-case-popup[data-index="${caseSwiper.activeIndex}"`);
        header.classList.add('menu-hidden');
        casePopup.classList.add('case-visible');
    })

})
const closeCaseBtns = document.querySelectorAll('.close-case-btn');
closeCaseBtns.forEach((el) => {
    el.addEventListener('click', () => {
        const casePopup = document.querySelector(`.slide-case-popup[data-index="${caseSwiper.activeIndex}"`);
        casePopup.classList.remove('case-visible');
    })
})
