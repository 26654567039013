import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollSmoother } from '../js/libs/ScrollSmoother';
import { scrolloverflow } from '../js/libs/scrolloverflow.min';

import { SplitText } from '../js/libs/splittext.min';


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrollSmoother);


document.querySelectorAll('a.popup-anchor').forEach(function (link) {
    link.addEventListener('click', function (event) {
        event.preventDefault();
        const scrollToHere = link.getAttribute('href');
        gsap.to(window, { duration: 2, scrollTo: scrollToHere, ease: "power2" });
    })
});

const mailBtn = document.querySelector('.mail.header-circle');
mailBtn.addEventListener('click', function (e) {
    e.preventDefault();
    gsap.to(window, { duration: 2, scrollTo: '#Contatti', ease: "power2" });
})



if (window.innerWidth > 768) {
    ScrollSmoother.create({
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });

    gsap.from(".service", {
        y: 10,
        opacity: 0,
        duration: 0.5,
        stagger: 0.25,
        scrollTrigger: {
            trigger: "#Servizi",
            start: "top center",
            end: "+=500",
        }
    });

    // Set initial state to hidden

    gsap.set(".desktop-case-history-wrapper .case-image", { autoAlpha: 0 });
    gsap.set(".desktop-case-history-wrapper .case-content", { autoAlpha: 0, y: 50 });
    gsap.set(".desktop-case-history-wrapper .contatti-img-wrapper", { autoAlpha: 0 });
    gsap.set(".desktop-case-history-wrapper .contatti-info-wrapper", { autoAlpha: 0, y: 50 });
    const singleCaseWrapper = document.querySelectorAll('.single-case-wrapper');
    singleCaseWrapper.forEach((single) => {
        const singleChild = single.querySelectorAll('.effectFade');

        gsap.from(single, {
            y: 10,
            opacity: 0,
            duration: 0.5,
            scrollTrigger: {
                trigger: single,
                start: "top bottom",
            },
            onComplete: () => {
                gsap.to(singleChild, { duration: 1, autoAlpha: 1, y: 0, stagger: 0.5 });
            }
        })
    })

    const singleContattiWrapper = document.querySelectorAll('.single-contatti-wrapper');
    singleContattiWrapper.forEach((single) => {
        const singleChild = single.querySelectorAll('.effectFade');

        gsap.from(single, {
            y: 10,
            opacity: 0,
            duration: 0.5,
            scrollTrigger: {
                trigger: single,
                start: "top bottom",
            },
            onComplete: () => {
                gsap.to(singleChild, { duration: 1, autoAlpha: 1, y: 0, stagger: 0.1 });
            }
        })
    })
}
let caseSection = document.querySelector('#CaseHistory');
const rect = caseSection.getBoundingClientRect();
const scrollTopBtn = document.querySelector('.scrollTopBtn');

window.addEventListener('scroll', function () {
    if (scrollY > rect.top) {
        scrollTopBtn.classList.add('visible');
    } else {
        scrollTopBtn.classList.remove('visible');

    }
});

scrollTopBtn.addEventListener('click', () => {
    gsap.to(window, {
        duration: 1,
        scrollTo: { y: 0 },
        ease: "power1",
    })
})