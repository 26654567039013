import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
headerRight = document.querySelector('.header-right');
header = document.querySelector('header');
navMenuBtn = document.querySelector('.hamburger-menu');
menuWrapper = document.querySelector('.hamburger-wrapper');
menuPopup = document.querySelector('.header-popup');
body = document.querySelector('body');


navMenuBtn.addEventListener('click', () => {
    menuWrapper.classList.toggle('menu-open');
    menuPopup.classList.toggle('menu-open');
    headerRight.classList.toggle('menu-open');
    body.classList.toggle('menu-open');
})

const windowHeight = window.clientHeight;

let lastScroll = 0;
document.addEventListener('scroll', () => {
    if (scrollY > 100 && scrollY > lastScroll) {
        header.classList.add('menu-hidden');
    } else if (scrollY > 100 && scrollY < lastScroll) {
        header.classList.remove('menu-hidden');
    }
    lastScroll = scrollY;
})
