let progressBar = document.querySelector(".inner-progress-bar");
let documentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
let progressBarTitle = document.querySelector(".progress-title");

window.onscroll = function () {
    let progress = (scrollY / documentHeight) * 105;

    progressBar.style.height = progress + "%";
}

if (window.innerWidth >= 1024) {
    function logSectionIdOnScroll(sectionIds) {
        sectionIds.forEach(function (sectionId) {
            const section = document.getElementById(sectionId);

            window.addEventListener('scroll', function () {
                const rect = section.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;

                if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
                    if (sectionId !== 'CaseHistory') {
                        progressBarTitle.innerText = sectionId;

                    } else {
                        progressBarTitle.innerText = 'Case History';

                    }
                } else if (scrollY < 300) {
                    progressBarTitle.innerText = 'home';
                }
            });
        });
    }
    // Array di ID delle sezioni
    const sectionIds = ['About', 'CaseHistory', 'Servizi', 'Contatti'];

    // Utilizzo: Chiamare questa funzione con l'array di ID delle sezioni che si desidera controllare
    logSectionIdOnScroll(sectionIds);
}


