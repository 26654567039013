const bio = document.querySelectorAll('.biography');
const showMoreBtn = document.querySelectorAll('.more-bio-btn');
const lessBioBtn = document.querySelectorAll('.less-bio-btn');


showMoreBtn.forEach((el) => {
  el.addEventListener('click', () => {
    bio.forEach((b) => {
      if (b.getAttribute('data-id') == el.getAttribute('data-id')) {


        b.classList.toggle('open-text');
        el.classList.toggle('hide-btn');
        if (b.classList.contains('open-text')) {
          b.style.height = null;
          b.style.maxHeight = null;

          setTimeout(() => {
            let bh = b.offsetHeight;
            b.style.height = `${bh}px`;
            b.style.maxHeight = `${bh}px`;

          }, 400)

        } else {

          let bh = b.offsetHeight;


          b.style.height = `90px`;
          b.style.maxHeight = `90px`;

        }
        if (el.classList.contains('hide-btn')) {

          el.innerHTML = 'Chiudi' + ` <svg
                    class="more-bio-icon"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="#6DB9DE"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_128_604)">
                      <path
                        d="M14.5 29C6.50438 29 0 22.4956 0 14.5C0 6.50438 6.50438 0 14.5 0C22.4956 0 29 6.50438 29 14.5C29 22.4956 22.4956 29 14.5 29ZM14.5 1.8125C7.50378 1.8125 1.8125 7.50378 1.8125 14.5C1.8125 21.4962 7.50378 27.1875 14.5 27.1875C21.4962 27.1875 27.1875 21.4962 27.1875 14.5C27.1875 7.50378 21.4962 1.8125 14.5 1.8125Z"
                      />
                      <path
                        d="M20.8438 15.4062H8.15625C7.656 15.4062 7.25 15.0003 7.25 14.5C7.25 13.9997 7.656 13.5938 8.15625 13.5938H20.8438C21.344 13.5938 21.75 13.9997 21.75 14.5C21.75 15.0003 21.344 15.4062 20.8438 15.4062Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_128_604">
                        <rect width="29" height="29" />
                      </clipPath>
                    </defs>
                  </svg>`;
        } else {
          el.innerHTML = 'Scopri' + ` <svg
                    class="more-bio-icon"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_111_117)">
                      <path
                        d="M14.5 29C6.50438 29 0 22.4956 0 14.5C0 6.50438 6.50438 0 14.5 0C22.4956 0 29 6.50438 29 14.5C29 22.4956 22.4956 29 14.5 29ZM14.5 1.8125C7.50378 1.8125 1.8125 7.50378 1.8125 14.5C1.8125 21.4962 7.50378 27.1875 14.5 27.1875C21.4962 27.1875 27.1875 21.4962 27.1875 14.5C27.1875 7.50378 21.4962 1.8125 14.5 1.8125Z"
                      />
                      <path
                        d="M20.8438 15.4062H8.15625C7.656 15.4062 7.25 15.0003 7.25 14.5C7.25 13.9997 7.656 13.5938 8.15625 13.5938H20.8438C21.344 13.5938 21.75 13.9997 21.75 14.5C21.75 15.0003 21.344 15.4062 20.8438 15.4062Z"
                      />
                      <path
                        d="M14.5 21.75C13.9997 21.75 13.5938 21.344 13.5938 20.8438V8.15625C13.5938 7.656 13.9997 7.25 14.5 7.25C15.0003 7.25 15.4062 7.656 15.4062 8.15625V20.8438C15.4062 21.344 15.0003 21.75 14.5 21.75Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_111_117">
                        <rect width="29" height="29" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>`;
        }

      }
    })
  })

})